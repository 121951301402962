import Header from "../../components/header/header";
import CarouselHome from "./components/carouselHome/carouselHome";
import style from "./home.module.css";
import foto3 from "../../assets/images/foto3.jpeg";
import foto4 from "../../assets/images/foto4.jpeg";
import foto5 from "../../assets/images/foto5.jpeg";
import foto8 from "../../assets/images/foto8.jpeg";
import ContactForm from "../../components/contactForm/contactForm";
import locatelLogo from "../../assets/images/locatel.png";
import Foooter from "../../components/footer/footer";
import React, { forwardRef } from "react";

export const ContactUsBlock = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div className="box box-drag" ref={ref}>
      <ContactForm />
    </div>
  );
});

export const WhoWeAreBlock = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div className={style.whoAreSection}>
      <div className="box box-drag" ref={ref}>
        <div className={style.whoAreSectionInfo}>
          <h1>Nosotros</h1>
          <p>
            Contamos con un equipo de profesionales enfocados principalmente en
            la{" "}
            <span className={style.highlight}>
              consultoría técnica de SAP y Ariba
            </span>
            , altamente calificados para solucionar diferentes tipos de
            necesidades de negocio, con un gran sentido de responsabilidad y
            pasión por su trabajo.
          </p>
        </div>
      </div>
    </div>
  );
});

export const ServicesBlock = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div className="box box-drag" ref={ref}>
      <div className={style.serviceSection}>
        <div className={style.serviceSectionInfo}>
          <img src={foto3} alt="" />
          <h1>Consultoría técnica SAP</h1>
          <p>
            Desarrolo de soluciones en sistemas SAP según los necesidades de
            nuestros clientes.
          </p>
        </div>
        <div className={style.serviceSectionInfo}>
          <img src={foto4} alt="" />
          <h1>Integraciones Ariba - ERP</h1>
          <p>
            Implementación y migracion de integraciones entre Ariba y sistemas
            ERP por medio de conectores propios SAP y externos
          </p>
        </div>
        <div className={style.serviceSectionInfo}>
          <img src={foto5} alt="" />
          <h1>Soporte y Mantenimiento</h1>
          <p>Mantenimiento continuo de sistemas SAP</p>
        </div>
        <div className={style.serviceSectionInfo}>
          <img src={foto8} alt="" />
          <h1>Optimización y mejoras</h1>
          <p>
            Análisis de procesos empresariales y recomendaciones para
            optimizarla configuración de SAP
          </p>
        </div>
      </div>
    </div>
  );
});

const Home = () => {
  var refIni = React.createRef<HTMLDivElement>();
  var refWho = React.createRef<HTMLDivElement>();
  var refSer = React.createRef<HTMLDivElement>();
  var refCon = React.createRef<HTMLDivElement>();

  return (
    <>
      <Header refIni={refIni} refWho={refWho} refSer={refSer} refCon={refCon} />
      <div className={style.container}>
        <CarouselHome ref={refIni} />
        <WhoWeAreBlock ref={refWho} />
        <ServicesBlock ref={refSer} />
        <ContactUsBlock ref={refCon} />
        <div className={style.alliesSection}>
          <h1>NUESTROS ALIADOS Y CLIENTES</h1>
          <div className={style.alliesContent}>
            <h2>Manejamos difrentes aliados puedes sumarte a esta lista</h2>
            <div className={style.alliesImages}>
              <img src={locatelLogo} alt="" />
            </div>
          </div>
        </div>
        <Foooter />
      </div>
    </>
  );
};

export default Home;
