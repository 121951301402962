import facebook from "../../assets/images/facebook.png";
import x from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";
//import style from "./contactForm.module.css";
import "./contactForm.scss";

const handleSubmit = () => {};

const handleInputChange = () => {};

const ContactForm = () => {
  return (
    <section className="contact-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="wrapper">
              <div className="row no-gutters">
                <div className="col-md-6">
                  <div className="contact-wrap w-100 p-lg-5 p-4">
                    <h4 className="mb-4">Envíenos un mensaje</h4>
                    <form
                      id="contactForm"
                      className="contactForm"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Nombre"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="lastname"
                              placeholder="Apellido"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              placeholder="Correo"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="phone"
                              placeholder="Telefono"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              name="message"
                              placeholder="Mensaje"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="submit"
                              value="Enviar"
                              className="btn btn-primary"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-6 d-flex align-item-stretch">
                  <div className="info-wrap w-100 p-lg-5 p-4 img">
                    <h4>Contáctenos</h4>
                    <p className="mb-4">
                      Sus solicitudes son muy importantes para nosotros. Uno de
                      nuestros expertos se pondra en contacto con usted.
                    </p>
                    <div className="row">
                      <div className="col-md-7">
                        <div className="mycontent-right">
                          <div className="dbox w-100 d-flex align-items-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa-solid fa-location-dot"></span>
                            </div>
                            <div className="text pl-3">
                              <p>Carrera 81a # 23b - 21 Bogotá, Colombia</p>
                            </div>
                          </div>
                          <div className="dbox w-100 d-flex align-items-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-phone"></span>
                            </div>
                            <div className="text pl-3">
                              <p>+57 310 2988186</p>
                            </div>
                          </div>
                          <div className="dbox w-100 d-flex align-items-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa-solid fa-envelope"></span>
                            </div>
                            <div className="text pl-3">
                              <p>info@sabnez.com</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <p className="mb4">Siganos:</p>
                        <div className="row">
                          <div className="col-auto mr-auto">
                            <a href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61558157466380">
                              <span className="fa-brands fa-facebook"></span>
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-auto mr-auto">
                            <a href="https://twitter.com/SabnezConsulti">
                              <span className="fa-brands fa-square-x-twitter"></span>
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-auto mr-auto">
                            <a href="https://www.linkedin.com/company/103402835/admin/feed/posts/?feedType=following">
                              <span className="fa-brands fa-linkedin"></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
