import style from "./footer.module.css";
import facebook from "../../assets/images/facebook.png";
import x from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";

const Foooter = () => {
  return (
    <div className={style.container}>
      <div className={style.footerContent}>
        <div className={style.footerSections}>
          <h1>SABNEZ</h1>
          <p>
            Expertos en ABAP no solo domina el código; transforman los procesos
            empresariales
          </p>
        </div>
        <div className={style.footerSections}>
          <h1>NAVEGACION</h1>
          <ul>
            <li>
              <a href="/">Inicio</a>
            </li>
            <li>
              <a href="/who-are">Quienes somos</a>
            </li>
            <li>
              <a href="/services">Servicios</a>
            </li>
            <li>
              <a href="/contact-us">Contactenos</a>
            </li>
          </ul>
        </div>
        <div className={style.footerSections}>
          <h1>Informacion y Contacto</h1>
          <div className={style.textGroup}>
            <h2>Direccion:</h2>
            <h3>Bogota Carrera 81a# 23b-21</h3>
          </div>
          <div className={style.textGroup}>
            <h2>Telefono:</h2>
            <h3>{`(+57) 3102988186`}</h3>
          </div>
        </div>
        <div className={style.socialMedia}>
          <a href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61558157466380">
            <img src={facebook} alt="" />
          </a>
          <a href="https://twitter.com/SabnezConsulti">
            <img src={x} alt="" />
          </a>
          <a href="https://www.linkedin.com/company/103402835/admin/feed/posts/?feedType=following">
            <img src={linkedin} alt="" />
          </a>
        </div>
      </div>
      <div className={style.copyrigth}>
        <p>Copyright © 2024 All Rights Reserved By SABNEZ </p>
      </div>
    </div>
  );
};

export default Foooter;
