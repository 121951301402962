import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/home";
import Services from "./pages/services/services";
import WhoAre from "./pages/whoAre/whoAre";
import ContactUs from "./pages/contactUs/contactUs";
import { Component } from "react";
import React from "react";

class App extends Component {
  sectionRef: any;
  constructor(props: any) {
    super(props);
    this.sectionRef = React.createRef();
  }

  public render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
