import style from "./header.module.css";
import logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import menu from "../../assets/images/menu.png";
import React, { useEffect, useState } from "react";

const Header = (reference: any) => {
  var section: string;
  const [secIniIsVisible, setSecIniIsVisible] = useState<boolean>();
  const [secSerIsVisible, setSecSerIsVisible] = useState<boolean>();
  const [secWhoIsVisible, setSecWhoIsVisible] = useState<boolean>();
  const [secConIsVisible, setSecConIsVisible] = useState<boolean>();
  var menuRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const observerIni = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setSecIniIsVisible(entry.isIntersecting);
    });
    const observerSer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setSecSerIsVisible(entry.isIntersecting);
    });
    const observerWho = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setSecWhoIsVisible(entry.isIntersecting);
    });
    const observerCon = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setSecConIsVisible(entry.isIntersecting);
    });
    observerIni.observe(reference.refIni.current);
    observerSer.observe(reference.refSer.current);
    observerWho.observe(reference.refWho.current);
    observerCon.observe(reference.refCon.current);
  });

  const scrollDown = (ref: any) => {
    window.scrollTo({
      top: ( ref.current.offsetTop - menuRef.current?.offsetHeight! ),
      behavior: "smooth",
    });
  };

  switch (true) {
    case secIniIsVisible:
      section! = "Ini";
      break;
    case secWhoIsVisible:
      section! = "Who";
      break;
    case secSerIsVisible:
      section = "Ser";
      break;
    case secConIsVisible:
      section = "Con";
      break;
  }

  return (
    <div className={style.container} ref={menuRef}>
      <img src={logo} alt="" className={style.logo} />
      <input type="checkbox" id="menu" />
      <label htmlFor="menu">
        <img src={menu} alt="" className={style.menuIcon} />
      </label>
      <div className={style.menuContainer}>
        <NavLink
          to="/"
          className={style.itemMenu}
          onClick={() => scrollDown(reference.refIni)}
          style={{ cursor: "pointer" }}
        >
          <h1>Inicio</h1>
          <span className={section! == "Ini" ? style.active : style.disable} />
        </NavLink>
        <NavLink
          to="/"
          className={style.itemMenu}
          onClick={() => scrollDown(reference.refWho)}
          style={{ cursor: "pointer" }}
        >
          <h1>Nosotros</h1>
          <span className={section! == "Who" ? style.active : style.disable} />
        </NavLink>
        <NavLink
          to="/"
          className={style.itemMenu}
          onClick={() => scrollDown(reference.refSer)}
          style={{ cursor: "pointer" }}
        >
          <h1>Servicios</h1>
          <span className={section! == "Ser" ? style.active : style.disable} />
        </NavLink>
        <NavLink
          to="/"
          className={style.itemMenu}
          onClick={() => scrollDown(reference.refCon)}
          style={{ cursor: "pointer" }}
        >
          <h1>Contactenos</h1>
          <span className={section! == "Con" ? style.active : style.disable} />
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
