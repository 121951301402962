import bgvideo from "../../../../assets/images/Web-background.mp4";
import bgvideo_mob from "../../../../assets/images/Web-background_mobile.mp4";
import { useEffect, useRef, forwardRef, useState } from "react";
import "./carouselHome.scss";
import { motion, px } from "framer-motion";

const CarouselHome = forwardRef<HTMLDivElement>((props, ref) => {
  const VidRef = useRef<HTMLVideoElement>(null);
  if (window.innerWidth > 769) {
    var myVid = bgvideo;;
  } else {
    myVid = bgvideo_mob;
  }

  useEffect(() => {
    function handleresize() {
      if (window.innerWidth > 769) {
        myVid = bgvideo;
      } else {
        myVid = bgvideo_mob;
      }

      if (VidRef.current!.attributes[3].nodeValue != myVid) {
        VidRef.current!.muted = true;
        VidRef.current!.src = myVid;
      }
    }
    window.addEventListener("resize", handleresize);
  }, []);

  return (
    <div className="box box-drag" ref={ref}>
      <div className="HeaderSection">
        <video muted autoPlay loop playsInline ref={VidRef} src={myVid!} />
        <div className="Content">
          <Fliplink href="#">IMPULSA TODO EL POTENCIAL</Fliplink>
          <Fliplink href="#">DE TU NEGOCIO</Fliplink>
          <p></p>
          <p>Expertos en consultoría técnica SAP y Ariba</p>
        </div>
      </div>
    </div>
  );
});

const Fliplink = ({ children, href }: { children: string; href: string }) => {
  const Duration = 0.25;
  const Stagger = 0.01;

  return (
    <motion.a
      initial="initial"
      whileHover="hovered"
      href={href}
      className="Flip"
      style={{
        cursor: "default",
      }}
    >
      <div>
        <motion.h1>
          {children.split("").map((l, i) => {
            if (l == " ") {
              var clName = "Inline1";
            } else {
              clName = "Inline";
            }
            return (
              <motion.span
                className={clName}
                variants={{
                  initial: { y: 0 },
                  hovered: { y: "-109%" },
                }}
                transition={{
                  duration: Duration,
                  delay: Stagger * i,
                }}
                key={i}
              >
                {l}
              </motion.span>
            );
          })}
        </motion.h1>
      </div>
      <div className="Flip2">
        <motion.h1>
          {children.split("").map((l, i) => {
            if (l == " ") {
              var clName = "Inline1";
            } else {
              clName = "Inline";
            }
            return (
              <motion.span
                className={clName}
                variants={{
                  initial: { y: "109%" },
                  hovered: { y: 0 },
                }}
                transition={{
                  duration: Duration,
                  delay: Stagger * i,
                }}
                key={i}
              >
                {l}
              </motion.span>
            );
          })}
        </motion.h1>
      </div>
    </motion.a>
  );
};

export default CarouselHome;
